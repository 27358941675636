import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    p: "p",
    pre: "pre",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "There is a good chance that you have probably never seen symbols used in JavaScript before. This is a primer on how we use them."
    }), "\n", _jsx(_components.h2, {
      id: "why-symbols",
      children: _jsx(_components.a, {
        href: "#why-symbols",
        children: "Why symbols?"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["There are sometimes multiple string representations of the same thing, for example, the ADC role in League of Legends is spelled as ", _jsx(_components.code, {
        children: "ADC"
      }), ", ", _jsx(_components.code, {
        children: "BOT"
      }), ", ", _jsx(_components.code, {
        children: "BOTTOM"
      }), ", and lowercase variants, depending on where it's used."]
    }), "\n", _jsx(_components.p, {
      children: "This makes doing checks like this insane:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "role === \"ADC\"; // might never match if it's not the string we expect\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Solution: cast ambiguous strings that mean the same thing to a symbol."
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "role === ROLE_SYMBOLS.ADC; // 😎\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Always use symbols or constants in application code, rather than hardcoding."
    }), "\n", _jsx(_components.h2, {
      id: "coerce-to-symbol-in-data-model",
      children: _jsx(_components.a, {
        href: "#coerce-to-symbol-in-data-model",
        children: "Coerce to symbol in data model"
      })
    }), "\n", _jsx(_components.p, {
      children: "The preferred way of getting symbols is by defining them in the data model:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  role: RoleSymbol,\n  rank: RankSymbol,\n  region: RegionSymbol,\n  queue: QueueSymbol,\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "This replaces whatever value was there before, with a symbol, to prevent application code from using string comparisons."
    }), "\n", _jsx(_components.h2, {
      id: "symbol-namemjs",
      children: _jsx(_components.a, {
        href: "#symbol-namemjs",
        children: _jsx(_components.code, {
          children: "symbol-name.mjs"
        })
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Rather than using the global symbol registry, we use a custom prefix so we can avoid namespace pollution. To guarantee that this custom prefix is used, there's the ", _jsx(_components.code, {
        children: "symbolName"
      }), " helper function."]
    }), "\n", _jsx(_components.p, {
      children: "This is also used for serializing/deserializing symbols in IndexedDB."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
